import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import AddressForm from "./AddressForm";
import LandlordForm from "./Landlord";
import SelectCityForm from "./SelectCityForm";
import UploadForm from "./UploadForm";
import SubmissionForm from "./SubmissionForm";
import IntroForm from "./IntroForm";
import IntroForm2 from "./IntoForm2";
import axios from "axios";

import Grid from "@material-ui/core/Grid";

import AllHome from "./images/allhome.png";
import BACS from "./images/bacs.png";
import koh from "./images/koh.png";

// import { BablicLanguage } from "./bablicLang";

import Modal from "./PrintModal.js";
import { sub } from "date-fns";

const imgStyle = {
	height: 65,
	// maxWidth: "80%",
	objectFit: "cover",
	display: "inline-block",
	margin: "0 auto",
	marginTop: 10,
	marginBottom: 10,
};

let langSet = false;

function Copyright(props) {
	return (
		<Typography variant="body2" color="textSecondary" align="center">
			<div>
				<img src={BACS} height={40} style={{ paddingRight: 10 }} />
				<img src={AllHome} height={40} style={{ paddingLeft: 10 }} />
			</div>
			<div>
				<span onClick={() => props.bypassRequired()}>Regional</span> Homelessness Prevention
				Platform
			</div>
			<div>
				© 2021 Bay Area Community Services, Inc. All{" "}
				<span onDoubleClick={() => props.setModalPrintOpen(true)}>Rights</span> Reserved
			</div>
		</Typography>
	);
}

const useStyles = makeStyles((theme) => ({
	appBar: {
		position: "relative",
	},
	layout: {
		width: "auto",
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
			//   width: "50vw",
			minWidth: 600,
			maxWidth: "95vw",
			marginLeft: "auto",
			marginRight: "auto",
		},
	},
	paper: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		[theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
			marginTop: theme.spacing(6),
			marginBottom: theme.spacing(6),
			padding: theme.spacing(3),
		},
	},
	stepper: {
		padding: theme.spacing(3, 0, 5),
	},
	buttons: {
		display: "flex",
		justifyContent: "flex-end",
	},
	button: {
		marginTop: theme.spacing(3),
		marginLeft: theme.spacing(1),
	},
}));

const steps = [
	"Overview",
	"Before You Begin",
	"Needed Documentation",
	"Applicant Information",
	//   "Landlord Information",
	"Placement Questionnaire",
	"Documentation",
	"Submission",
];

function getStepContent(
	step,
	formData,
	setFormData,
	fileData,
	setFileData,
	isSubmitting,
	setSubmitting,
	errorFields,
	setErrorFields,
	suggestedAddress,
	setSuggestedAddress,
	selectedAddress,
	setSelectedAddress,
	setActiveStep
) {
	switch (step) {
		case 0:
			return (
				<SelectCityForm
					formData={formData}
					setFormData={setFormData}
					setActiveStep={setActiveStep}
				/>
			);
		case 1:
			return <IntroForm2 formData={formData} setFormData={setFormData} fileData={fileData} />;
		case 2:
			return <IntroForm formData={formData} setFormData={setFormData} fileData={fileData} />;
		case 3:
			return (
				<AddressForm
					formData={formData}
					setFormData={setFormData}
					errorFields={errorFields}
					setErrorFields={setErrorFields}
					suggestedAddress={suggestedAddress}
					setSuggestedAddress={setSuggestedAddress}
					selectedAddress={selectedAddress}
					setSelectedAddress={setSelectedAddress}
				/>
			);
		// case 1:
		//   return <LandlordForm formData={formData} setFormData={setFormData} />;
		case 4:
			return <LandlordForm formData={formData} setFormData={setFormData} />;
		case 5:
			return (
				<UploadForm
					formData={formData}
					setFormData={setFormData}
					fileData={fileData}
					setFileData={setFileData}
				/>
			);
		case 6:
			return (
				<SubmissionForm
					formData={formData}
					setFormData={setFormData}
					fileData={fileData}
					setFileData={setFileData}
					isSubmitting={isSubmitting}
					setSubmitting={setSubmitting}
				/>
			);
		default:
			throw new Error("Unknown step");
	}
}

export default function Checkout() {
	const classes = useStyles();
	const [suggestedAddress, setSuggestedAddress] = React.useState({ address: [] });
	const [selectedAddress, setSelectedAddress] = React.useState(null);
	const [errorFields, setErrorFields] = React.useState({});
	const [activeStep, setActiveStep] = React.useState(0);
	const [modalPrintOpen, setModalPrintOpen] = React.useState(false);
	const [isSubmitting, setSubmitting] = React.useState(false);
	const [fileData, setFileData] = React.useState({
		id: { uploaded: false, name: "", files: [] },
		lease: { uploaded: false, name: "", files: [] },
		rentalPayments: { uploaded: false, name: "", files: [] },
		incomeLossDoc: { uploaded: false, name: "", files: [] },
		housingCrisisProof: { uploaded: false, name: "", files: [] },
		landlordW9: { uploaded: false, name: "", files: [] },
	});
	const [formData, setFormData] = React.useState({
		tempWarning: false,
		tempWarningQ1: true,
		tempWarningQ2: true,
		ready: false,
		error: false,
		bypassRequired: true,
		name: "",
		agreed: false,
		agreedTrue: false,
		agreedFalsification: false,
		agreedAssistance: false,
		agreedCityConsent: false,
		agreedCommunications: false,
		agreedParticipate: false,
		signedName: null,
		score: new Array(12).fill(0),
		amiCalculator: 1,
		amiCalculator2: 1,
		submitError: false,
		submitErrorMessage: "",
		submittedBy: "MYSELF",
		ethnicityArray: [],
		whatCity: "San Francisco",
		dob: null,
		evictionNoticeDate: null,
		files: {
			id: { uploaded: false, name: "" },
			lease: { uploaded: false, name: "" },
			rentalPayments: { uploaded: false, name: "" },
			incomeLossDoc: { uploaded: false, name: "" },
			housingCrisisProof: { uploaded: false, name: "" },
			landlordW9: { uploaded: false, name: "" },
		},
		submissionID: Date.now()
			.toString()
			.match(/\d{3}(?=\d{2,3})|\d+/g)
			.join("-"),
	});

	let eventListenerSet = false;

	try {
		var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
		if (!isSafari) {
			window.history.pushState(
				{ name: "browserBack" },
				"on browser back click",
				window.location.href
			);
			window.history.pushState(
				{ name: "browserBack" },
				"on browser back click",
				window.location.href
			);
			if (!eventListenerSet) {
				window.addEventListener(
					"popstate",
					(event) => {
						if (event.state) {
							if (activeStep > 0) handleBack();
						}
					},
					false
				);
				eventListenerSet = true;
			}
		}
	} catch (e) {
		console.error("error in history push state", e);
	}

	let buttonEnabled = false;

	const handleSetSubmit = async (toSet) => {
		// console.log("setting submitting to ", toSet);
		await setSubmitting(toSet);
		return;
	};

	const handleNext = async (evt) => {
		console.log("handling next");
		await setFormData({ ...formData, error: false });

		if (
			activeStep == 0 &&
			formData.tempWarning &&
			formData.tempWarningQ1 &&
			formData.tempWarningQ2
		) {
			console.log(
				"active step",
				activeStep,
				"tempwearning",
				tempWarning,
				"q1",
				tempWarningQ1,
				"q2",
				tempWarningQ2
			);
			handleSetFormData({ ...formData, tempWarning: false });
		} else {
			if (
				formData.bypassRequired &&
				document.querySelectorAll(":required").length > 0 &&
				[].map.call(document.querySelectorAll(":required"), (el) => el.value).indexOf("") >= 0
			) {
				// console.log("missing required fields");
				const errorFields = document.querySelectorAll(":required");
				let firstError;
				for (var i = 0; i < errorFields.length; i++) {
					const eField = errorFields[i];
					if (eField.value == "") console.log("error field name", eField);
				}
				errorFields.forEach((e) => (!firstError && e.value == "" ? (firstError = e) : null));
				console.log("errorFields", errorFields, firstError, formData);
				firstError.scrollIntoView();

				return await handleSetFormData({ ...formData, error: true });
			} else {
				console.log("no error");
				await handleSetFormData({ ...formData, error: false });
			}

			try {
				document.getElementById("appHeader").scrollIntoView();
			} catch (err) {
				console.error("error scrolling to app Header");
			}
			let response;
			await setActiveStep(activeStep + 1);
			if (activeStep + 1 == steps.length) await handleSetSubmit(true);
			if (activeStep + 1 == steps.length) return await handleSubmission();
		}
	};

	const handleSubmission = async () => {
		let submissionData = { ...formData };
		// await setSubmitting(true);
		// console.log("set submitting");
		try {
			if (window.bablic && window.bablic.locale) {
				console.log("setting language used", window.bablic.locale);
				submissionData = { ...submissionData, languageUsed: window.bablic.locale };
				formData.languageUsed = window.bablic.locale;
				// await setFormData({ ...formData, languageUsed: window.bablic.locale });
				console.log("completed set form data", submissionData);
			} else {
				console.log("no window bablic detected", window.bablic);
			}
			console.log("pre-submit form data", submissionData);
			console.log("formdata", formData);
			const response = await axios.post(
				"https://sferap.com/api/form/create",
				{ formData },
				{ headers: { "Content-Type": "application/json" } }
			);
			console.log("response", response);
			if (response.data && response.data.error) {
				return handleSetFormData({
					...formData,
					submitError: true,
					submitErrorMessage: "Error in Submission\n\n" + response.data.error,
				});
			}
			await handleSetFormData({
				...formData,
				formID: response.data.uploadResponse,
				submitError: false,
				submitErrorMessage: null,
			});
			await handleFiles(response.data.submissionID);
			return response;
		} catch (e) {
			console.log("error in submit", e);
			handleSetFormData({
				...formData,
				submitError: true,
				submitErrorMessage: "Error in Submission",
			});
		}
	};

	const handleFiles = async (formID) => {
		try {
			// console.log("handling files", fileData);
			const fileKeys = Object.keys(fileData);
			// console.log("fileKeys", fileKeys);
			const url = "https://sferap.com/api/form/upload";

			// let files = [];

			for (var i = 0; i < fileKeys.length; i++) {
				const key = fileKeys[i];
				const files = fileData[key].files;
				// console.log("files", files, files.length);
				if (files)
					for (var fileCount = 0; fileCount < files.length; fileCount++) {
						const data = new FormData();
						data.append("submissionID", formID);
						// console.log("fileCount", fileCount);
						const file = files[fileCount];

						// console.log("file", file);
						if (file && file.name && file.toUpload) {
							// console.log("uploading file", file.name);
							// let f = formData.files[fileKeys[i]].file;
							data.append("file", file);
						}
						const config = {
							headers: {
								"content-type": "multipart/form-data",
							},
						};
						const fileSubmitRequest = await axios.post(url, data, config);
					}
			}
			// data.append("files", files);
			// console.log("file data", data);

			await handleSetSubmit(false);
			// console.log("submitting done");
			return true;
		} catch (e) {
			console.error("error in handle files", e);
		}
	};

	const updateLangUsed = (value) => {
		console.log("updating form data lang used", formData);
		setFormData({ ...formData, ["languageUsed"]: value });
	};

	const handleBack = async () => {
		if (activeStep > 0) await setActiveStep(activeStep - 1);
		await handleSetSubmit(false);
	};

	const handleSetFormData = async (data) => {
		await setFormData(data);
	};

	const {
		agreed,
		agreedTrue,
		agreedFalsification,
		agreedAssistance,
		agreedCityConsent,
		agreedCommunications,
		agreedParticipate,
		signedName,
		bypassRequired,
		submitError,
		submitErrorMessage,
		whatCity,
		ready,
		tempWarning,
		tempWarningQ1,
		tempWarningQ2,
	} = formData;

	window.setLanguage = updateLangUsed;

	// setTimeout(function () {
	// 	// console.log("setting bablic listener");
	// 	try {
	// 		if (window.bablic && !formData.languageUsed) updateLangUsed(window.bablic.locale);
	// 		{
	// 			if (!langSet) {
	// 				langSet = true;
	// 				window.bablic.on("translated", function (a, b) {
	// 					updateLangUsed(a.locale);
	// 					// console.log("page translated", a.locale, BablicLanguage, BablicLanguage[a.locale]);
	// 				});
	// 			}
	// 		}
	// 	} catch (e) {
	// 		console.log("error setting bablic used language", e);
	// 	}
	// 	// console.log("END setting bablic listener");
	// }, 1250);

	return (
		<React.Fragment>
			{modalPrintOpen && (
				<Modal
					test={"testing 123"}
					open={modalPrintOpen}
					setOpen={setModalPrintOpen}
					formData={formData}
					fileData={fileData}
				></Modal>
			)}
			<CssBaseline />
			<main className={classes.layout}>
				<Paper className={`${classes.paper} translate`}>
					<Typography
						component="h1"
						variant="h4"
						align="center"
						style={{ fontWeight: 200 }}
						// onClick={() => setModalPrintOpen(true)}
					>
						Emergency Rental Assistance Application
					</Typography>
					<Stepper
						activeStep={activeStep}
						className={classes.stepper}
						orientation={window.innerWidth < 650 ? "vertical" : "horizontal"}
					>
						{steps.map((label) => (
							<Step key={label}>
								<StepLabel>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<React.Fragment>
						{activeStep === steps.length ? (
							<React.Fragment>
								{isSubmitting && !submitError && (
									<div>
										{" "}
										<CircularProgress /> <div>Submitting Form - Please Wait</div>
									</div>
								)}
								{!isSubmitting && !submitError && (
									<div>
										<Typography variant="h5" gutterBottom>
											Application Submitted Successfully
										</Typography>
										<Typography>Your Application ID is: {formData.submissionID}</Typography>
										<Typography variant="subtitle1" style={{ marginTop: 10 }}>
											Thank you for submitting your application. You will receive an email
											confirming your submission.
										</Typography>
										<Typography variant="subtitle1" style={{ marginTop: 10 }}>
											You are encouraged to download and save a copy of your submission responses
											for your records. This will be helpful when working with your assigned case
											manager to process your application
										</Typography>

										<div>
											<Button
												variant="contained"
												color="primary"
												onClick={() => setModalPrintOpen(true)}
												className={classes.button}
											>
												Click to View & Print Completed Form
											</Button>
											{/* <span onDoubleClick={() => setModalPrintOpen(true)}>Rights</span> */}
										</div>
									</div>
								)}
								{submitError && (
									<div>
										<Typography style={{ color: "red" }} variant="h5">
											{submitErrorMessage}
											<br />
											Please click BACK and try your submission again.
										</Typography>
										<div className={classes.buttons}>
											{activeStep !== -1 && (
												<Button
													disabled={activeStep <= 0}
													onClick={handleBack}
													className={classes.button}
													style={{ display: !activeStep || activeStep == 0 ? "none" : "" }}
												>
													Back
												</Button>
											)}
										</div>
									</div>
								)}
							</React.Fragment>
						) : (
							<React.Fragment>
								{getStepContent(
									activeStep,
									formData,
									handleSetFormData,
									fileData,
									setFileData,
									isSubmitting,
									setSubmitting,
									errorFields,
									setErrorFields,
									suggestedAddress,
									setSuggestedAddress,
									selectedAddress,
									setSelectedAddress,
									setActiveStep,
									ready
								)}
								<div className={classes.buttons}>
									{activeStep !== -1 && (
										<Button
											disabled={activeStep <= 0}
											onClick={handleBack}
											className={`${classes.button} backButton`}
											style={{ display: !activeStep || activeStep == 0 ? "none" : "" }}
										>
											Back
										</Button>
									)}
									<Button
										variant="contained"
										color="primary"
										onClick={handleNext}
										className={classes.button}
										// style={{display: !activeStep || (activeStep && !ready) == 0 ? 'none' : ''}}
										disabled={
											//(activeStep === 0 && !ready) || (activeStep === steps.length - 1) && (!agreedAssistance || !agreedCityConsent || !agreedCommunications || !agreedFalsification || !agreedTrue || !agreed || !signedName || signedName == "")
											(!tempWarningQ1 && !tempWarningQ2) ||
											(activeStep === steps.length - 1 &&
												(!agreedAssistance ||
													!agreedCityConsent ||
													!agreedCommunications ||
													!agreedFalsification ||
													!agreedTrue ||
													!agreed ||
													!signedName ||
													signedName == ""))
										}
									>
										{activeStep === steps.length - 1 ? "Submit" : "Next"}
									</Button>
								</div>
							</React.Fragment>
						)}
					</React.Fragment>
				</Paper>
				<div
					style={{ paddingBottom: 25 }}
					onClick={() =>
						//handleSetFormData({ ...formData, bypassRequired: !bypassRequired })
						console.log("false")
					}
				>
					<Copyright
						setModalPrintOpen={() => setModalPrintOpen(true)}
						bypassRequired={() =>
							handleSetFormData({ ...formData, bypassRequired: !bypassRequired })
						}
					/>
				</div>
			</main>
		</React.Fragment>
	);
}
